import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import motumIcon from "./../../../assets/img/motum.svg";
import { UserContext } from "../../context/UserContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShopkeeperService from "../../../services/ShopkeeperService";
import {
    Sidebar, Menu, MenuItem,/*, SubMenu*/
} from 'react-pro-sidebar';
import { faTasks, faCheck, faBan, faTruckFast, faSignOut, faBars, faClipboardList, faCloudArrowUp, faWallet, faHeadset } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import Swal from "sweetalert2";
import IfoodService from "../../../services/IfoodService";
import Select from 'react-select';


const CustomSidebar = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const { user, selectedStore, stores, updateStore, setHideSidebar } = useContext(UserContext);
    // eslint-disable-next-line no-unused-vars
    const [numberPendingUsers, setNumberPendingUsers] = useState(0);
    const [loading, setLoading] = useState(false);
    const ifoodService = useMemo(() => new IfoodService(), []);

    async function loadUsers() {
        if (loading) return;
        setLoading(true);
        const shopkeeperService = new ShopkeeperService();
        const response = await shopkeeperService.getPendingUsers(1);
        if (response.data.length > 0) {
            setNumberPendingUsers(response.data.length);
        }
        setLoading(false);
    }

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line
    }, []);

    function logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('store');
        localStorage.removeItem('stores');
        setHideSidebar(true);
        window.location.href = window.location.origin + "/login";
    }

    useEffect(() => {
        if (props.windowWidth <= 900) {
            setSidebarOpen(false);
        } else {
            setSidebarOpen(true);
        }
    }, [props.windowWidth]);

    function renameKeys(obj) {
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map(item => renameKeys(item));
        }

        const newObj = {};
        for (let key in obj) {
            let newKey = key === '_id' ? 'id' : key;
            newObj[newKey] = renameKeys(obj[key]);
        }
        return newObj;
    }

    async function requestIFoodIntegration() {
        const { value: IfoodStoreId } = await Swal.fire({
            title: 'Informe o ID da Loja no IFood',
            input: 'text',
            html: `
            <label for="swal-input1">
                Busque o campo "ID da Loja" no Portal do Parceiro -> Minha loja -> Loja
                <a href="https://portal.ifood.com.br/profile/restaurant" target="_blank">
                    <i class="fa-solid fa-up-right-from-square" style="color: #000; margin-left: 10px;"></i>
                </a>
            </label>`,
            inputPlaceholder: 'Digite seu ID da Loja aqui',
            showCancelButton: true,
            confirmButtonColor: '#EA1D2C',
            cancelButtonColor: '#ccc',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Solicitar',
            width: "50%",
            preConfirm: (inputValue) => {
                if (!inputValue) {
                    Swal.showValidationMessage("Por favor, insira o ID da Loja");
                    return false;
                }

                return inputValue;
            }
        });
        if (IfoodStoreId) {
            try {
                const store = renameKeys(selectedStore);
                const response = await ifoodService.storeIfoodIntegrations(store, IfoodStoreId);
                if (response) {
                    Swal.fire({
                        title: 'Integração solicitada com sucesso!',
                        text: 'Aguarde a administração da plataforma aceitar sua integração',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                    });
                } else {
                    Swal.fire({
                        title: 'Ocorreu um erro ao solicitar a integração!',
                        icon: 'error',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    const storeOptions = stores.map(({ _id, companyName }) => ({ value: _id, label: companyName }));

    async function handleChangeStore(storeOption) {
        props.setSpinner(true);
        await updateStore(storeOption);
        setTimeout(() => {
            props.setSpinner(false);
        }, 2000)
    }

    return (
        <Sidebar
            collapsed={!sidebarOpen}
            onToggle={() => setSidebarOpen(!sidebarOpen)}

            breakPoint="md"
            className="sidebar"
        >
            <div className="pro-sidebar-header">
                {sidebarOpen ? (
                    <img src={motumIcon} alt="Motum" className="rodape__logo" />
                ) : (
                    <FontAwesomeIcon icon={faBars} className="toggle-btn" />
                )}
            </div>
            {(user.type === "FRANCHISEE" || user.type === "ADMIN") && (
                <div className="sidebar-select-store">
                    <Select
                        options={storeOptions}
                        onChange={handleChangeStore}
                        placeholder={'Selecione uma Loja'}
                        className="select-store-sidebar-container"
                        classNamePrefix="select-store-sidebar"
                        unstyled={true}
                        defaultValue={{ value: selectedStore._id, label: selectedStore.companyName }}
                    />
                </div>
            )}

            <Menu iconShape="circle">
                <div className="dashboard-links-container">

                    {user.type !== "ADMIN" && (
                        <MenuItem icon={<FontAwesomeIcon icon={faTruckFast} />}>
                            <Link to="/request">Solicitar entrega</Link>
                        </MenuItem>
                    )}

                    <MenuItem icon={<FontAwesomeIcon icon={faTasks} />}>
                        <Link to="/followup">Acompanhamento</Link>
                    </MenuItem>

                    {user.type !== "admin" && user.integration && user.integration.status === "INTEGRATED" ? (
                        <MenuItem icon={<FontAwesomeIcon icon={faClipboardList} />}>
                            <Link to="/management">Pedidos IFood</Link>
                        </MenuItem>
                    ) : (null)}


                    <MenuItem icon={<FontAwesomeIcon icon={faCheck} />}>
                        <Link to="/deliveriesmade">Entregas Concluídas</Link>
                    </MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={faBan} />}>
                        <Link to="/canceleddeliveries">Cancelamentos</Link>
                    </MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={faWallet} />}>
                        <Link to="/wallet">Carteira Digital</Link>
                    </MenuItem>

                    {user.type === "ADMIN" && (
                        <MenuItem icon={<FontAwesomeIcon icon={faHeadset} />}>
                            <Link to="/support">Suporte ao Usuário</Link>
                        </MenuItem>
                    )}
                    {user.type !== "ADMIN" && selectedStore.ifoodStore && selectedStore.ifoodIntegrationStatus === "PENDING" ? (
                        <MenuItem icon={<FontAwesomeIcon icon={faCloudArrowUp} />} className="menu-ifood-intergration-menu" onClick={requestIFoodIntegration}>
                            Integração IFood
                        </MenuItem>
                    ) : (null)}
                </div>

                <MenuItem icon={<FontAwesomeIcon icon={faSignOut} />} onClick={logout}>
                    Sair
                </MenuItem>
            </Menu>
        </Sidebar>
    );
};

export default CustomSidebar;
