import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import { faChartSimple, faCheckSquare, faHeadset, faNetworkWired, faSquareXmark, faUserCheck, faUserPen, faWallet } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import AdminService from '../../../services/AdminService';
import Conversor from '../../../helper/Conversor';

export default function Admin(props) {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const adminService = useMemo(() => new AdminService(), []);

    async function loadLogs() {
        if (loading) {
            return;
          }
          setLoading(true);
          props.setSpinner(true);
          setTimeout(async () => {
            const response = await adminService.getLogs();
            setLogs(response);
            props.setSpinner(false)
          }, 2000);
    }

    useEffect(() => {
        loadLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    function redirect(path) {
        window.location.href = window.location.origin + path;
    }

    return (
        <div className="content-container admin-container">
            <div className="support-actions-container">
                <div className="action-box" onClick={() => redirect("/pendingusers")}>
                    <FontAwesomeIcon className="action-icon" icon={faUserCheck} />
                    <div className="action-label">Aprovar usuários</div>
                </div>
                <div className="action-box" onClick={() => redirect("/integrations")}>
                    <FontAwesomeIcon className="action-icon" icon={faNetworkWired} />
                    <div className="action-label">Integrações</div>
                </div>
                <div className="action-box" onClick={() => redirect("/manageusers")}>
                    <FontAwesomeIcon className="action-icon" icon={faUserPen} />
                    <div className="action-label">Alterar cadastro</div>
                </div>
                <div className="action-box" onClick={() => redirect("/managewallet")}>
                    <FontAwesomeIcon className="action-icon" icon={faWallet} />
                    <div className="action-label">Carteiras</div>
                </div>
                <div className="action-box" onClick={() => redirect("/registersupport")}>
                    <FontAwesomeIcon className="action-icon" icon={faHeadset} />
                    <div className="action-label">Cadastrar Suporte</div>
                </div>
                <div className="action-box"  onClick={() => redirect("/reports")}>
                    <FontAwesomeIcon className="action-icon" icon={faChartSimple} />
                    <div className="action-label">Relatórios</div>
                </div>
            </div>
            <div className="activity-logs-container">
                <div className="activity-logs-title">Atividades no Sistema</div>
                <div className="activity-logs-list">
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Tipo</th>
                                <th>Usuário</th>
                                <th>Descrição</th>
                                <th>Status</th>
                                <th>Recurso</th>
                                <th>Localização</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs.length > 0 && logs.map((log) => (
                                <tr>
                                    <td>{Conversor.converterData(log.timestamp)}</td>
                                    <td>{log.activityType}</td>
                                    <td>{log.userName}</td>
                                    <td>{log.description}</td>
                                    <td className='status-label'>{log.status === "Success" ? <FontAwesomeIcon icon={faCheckSquare} color='green' /> : <FontAwesomeIcon icon={faSquareXmark} color='red' />}</td>
                                    <td>{log.resourceId}</td>
                                    <td>{log.ipAddress}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}