import React, { useContext, useState } from "react";
import motumIcon from "./../../../assets/img/motum-logo.png";
import HomePage from "./components/HomePage";
import Aboutus from "./components/Aboutus";
import Services from "./components/Services";
import Contact from "./components/Contact";
import { Navigate } from "react-router";
import './style.scss';
import { UserContext } from "../../context/UserContext";

export default function Home(props) {
    const [redirect, setRedirect] = useState(false);
    const [selectedPage, setSelectedPage] = useState("Home");
    const [previousPage, setPreviousPage] = useState(null);
    const { hideSidebar, setHideSidebar, user, selectedStore } = useContext(UserContext);

    if (redirect) {
        if(user && selectedStore) {
            setHideSidebar(false);
            return <Navigate to={"/request"} />;

        }
        else {
            setHideSidebar(true);
            return <Navigate to={"/login"} />;
        }
        
    }
        

    const handlePageChange = (page) => {
        if (page !== selectedPage) {
            setPreviousPage(selectedPage);
            setSelectedPage(page);
        }
    };

    if (!hideSidebar) {
        setHideSidebar(true);
    }

    return (
        <React.Fragment>
            <main className="main home-container" data-render-section>
                <div className="motum-home-header">
                    <div className="logo-container">
                        <img src={motumIcon} alt="Motum" className="motum-logo" />
                    </div>
                    <div className="nav-container">
                        <div className="link-container">
                            <button type="button" onClick={() => handlePageChange("Home")}>Home</button>
                            {selectedPage === "Home" ? (<div className="selected-link">.</div>) : null}
                        </div>
                        <div className="link-container">
                            <button type="button" onClick={() => handlePageChange("Aboutus")}>Sobre nós</button>
                            {selectedPage === "Aboutus" ? (<div className="selected-link">.</div>) : null}
                        </div>
                        <div className="link-container">
                            <button type="button" onClick={() => handlePageChange("Services")}>Serviços</button>
                            {selectedPage === "Services" ? (<div className="selected-link">.</div>) : null}
                        </div>
                        {/* 
                        TODO: Descomentar quando for criar a tela de contato
                        <div className="link-container">
                            <button type="button" onClick={() => handlePageChange("Contact")}>Contato</button>
                            {selectedPage === "Contact" ? (<div className="selected-link">.</div>) : null}
                        </div> */}
                        <div className="header-button">
                            <button type="button" onClick={() => setRedirect(true)}>Acesse o portal</button>
                        </div>
                    </div>
                </div>
                <div className="motum-home-main">
                    <div className={`page-transition ${selectedPage === "Home" ? 'fade-in' : 'fade-out'} ${previousPage === "Home" ? 'slide-out' : ''}`}>
                        {selectedPage === "Home" ? (<HomePage setRedirect={setRedirect}/>) : null}
                    </div>
                    <div className={`page-transition ${selectedPage === "Aboutus" ? 'fade-in' : 'fade-out'} ${previousPage === "Aboutus" ? 'slide-out' : ''}`}>
                        {selectedPage === "Aboutus" ? (<Aboutus />) : null}
                    </div>
                    <div className={`page-transition ${selectedPage === "Services" ? 'fade-in' : 'fade-out'} ${previousPage === "Services" ? 'slide-out' : ''}`}>
                        {selectedPage === "Services" ? (<Services />) : null}
                    </div>
                    <div className={`page-transition ${selectedPage === "Contact" ? 'fade-in' : 'fade-out'} ${previousPage === "Contact" ? 'slide-out' : ''}`}>
                        {selectedPage === "Contact" ? (<Contact />) : null}
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
}
