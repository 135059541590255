import aboutUsOne from "../../../../assets/img/about-us-one.png";
import aboutUsTwo from "../../../../assets/img/about-us-two.png";

export default function Aboutus() {
    return (
        <div className="about-us-container">
            <div className="about-us-content">
                <div className="image-container">
                    <img src={aboutUsOne} alt="Entrega" />
                </div>
                <div className="text-container">
                    <h2>Conheça um pouco mais sobre nós...</h2>
                    <h6>Nascemos da oportunidade de melhorar a qualidade da entrega de encomendas aqui do nosso estado.</h6>
                </div>
            </div>

            <div className="about-us-content">
                <div className="text-container">
                    <h2>Sua segurança em primeiro lugar</h2>
                </div>
                <div className="image-container">
                    <img src={aboutUsTwo} alt="Entrega" />
                </div>
            </div>
        </div>
    );
}