import React, { useState, useContext, useEffect } from "react";
import { Navigate } from 'react-router';
import ShopkeeperService from "../../../services/ShopkeeperService";
import { UserContext } from "../../context/UserContext";
import './style.scss';
import UsersList from "../../layout/UsersList/UsersList";
import Swal from "sweetalert2";

const PendingUsers = (props) => {
    const { user } = useContext(UserContext);
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    async function loadUsers() {
        if (loading) {
            return;
        }
        setLoading(true);
        props.setSpinner(true);
        setTimeout(async () => {
            const shopkeeperService = new ShopkeeperService();
            const response = await shopkeeperService.getPendingUsers(currentPage);
            if (response.data.length > 0) {
                setUsers(response.data);
            } else {
                setCurrentPage(currentPage - 1)
            }
            props.setSpinner(false);
            props.setTextSpinner('');
        }, 2000);

    }

    function nextPage() {
        setCurrentPage(currentPage + 1);
        setLoading(false);
    }

    function lastPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
            setLoading(false);
        }
    }

    async function aproveUser(id, email) {
        const result = await Swal.fire({
            title: 'Deseja aprovar esse usuário?',
            text: 'O usuário receberá um email de aprovação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Confirmar!',
            cancelButtonText: 'Voltar',
        });

        if (result.isConfirmed) {
            props.setSpinner(true);
            setTimeout(async () => {
                try {
                    const shopkeeperService = new ShopkeeperService();
                    const response = await shopkeeperService.aproveUser(id, email);
                    if (response.ok) {
                        Swal.fire({
                            title: 'Usuário aprovado!',
                            text: 'Tudo certo! O lojista agora poderá fazer login na plataforma.',
                            icon: 'success',
                            timer: 3000,
                            showConfirmButton: false,
                            didClose: () => {
                                setLoading(false);
                            }
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Ocorreu um erro!',
                        text: error,
                        icon: 'error',
                        timer: 3000,
                        showConfirmButton: false,
                        didClose: () => {
                            setLoading(false);
                        }
                    });
                }
            }, 2000);
        }
    }

    async function disaproveUser(id, email) {
        const result = await Swal.fire({
            title: 'Deseja reprovar esse usuário?',
            text: 'O usuário receberá um email informando a reprovação!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#17a2b8',
            confirmButtonText: 'Confirmar!',
            cancelButtonText: 'Voltar',
        });

        if (result.isConfirmed) {
            props.setSpinner(true);
            setTimeout(async () => {
                try {
                    const shopkeeperService = new ShopkeeperService();
                    const response = await shopkeeperService.disaproveUser(id, email);
                    if (response.ok) {
                        Swal.fire({
                            title: 'Usuário reprovado!',
                            icon: 'success',
                            timer: 3000,
                            showConfirmButton: false,
                            didClose: () => {
                                setLoading(false);
                            }
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Ocorreu um erro!',
                        text: error,
                        icon: 'error',
                        timer: 3000,
                        showConfirmButton: false,
                        didClose: () => {
                            setLoading(false);
                        }
                    });
                }
            }, 2000);
        }
    }

    useEffect(() => {
        loadUsers();
        document.title = `Aprovar Usuários | Motum`;
        // eslint-disable-next-line
    }, [currentPage, loading]);

    if (!user) {
        return <Navigate to={'/login'} />;
    }

    return (
        <React.Fragment>
            <div className="content-container">
                <UsersList
                    users={users}
                    nextPage={nextPage}
                    lastPage={lastPage}
                    aproveUser={aproveUser}
                    disaproveUser={disaproveUser}
                    title={"Usuários Pendentes"}
                />
            </div>
        </React.Fragment>
    );
}

export default PendingUsers;