import React, { useState } from 'react';
import Conversor from '../../../helper/Conversor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faTimes,
  faCheck,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
// import "./style.scss";

const UserDetailsModal = ({ user, closeModal, modalVisible, aproveUser, disaproveUser }) => {

  let storeAddress = '';
  let storeAddressNumber = '';
  let storeAddressComplement = '';

  if (user.store) {
    storeAddress = user.store.address;
    storeAddressNumber = storeAddress.number ? storeAddress.number : 's/n';
    storeAddressComplement = storeAddress.complement ? ', ' + storeAddress.complement + ', ' : ', ';
  }


  return (
    <div className="order-details-modal" style={{ flexDirection: 'column' }}>
      <div className='header-container' style={{ display: 'flex', justifyContent: 'end', marginBottom: 20 }}>
        <h2 className="order-details-title" style={{ marginRight: "30%" }}>{user.store ? "Detalhes do Lojista" : "Detalhes do Entregador"}</h2>
        <button onClick={closeModal} style={{ background: 'none', border: 'none', padding: 0 }}>
          <FontAwesomeIcon icon={faTimes} color='black' title='Reprovar Usuário' style={{ height: 25 }} />
        </button>
      </div>
      <div className="content-container" style={{ display: 'flex' }}>
        <div className="container-one">
          <div className="delivery-man-container-modal delivery-man-container">
            <h4>Dados Pessoais</h4>
            <p className='details-title'>Nome Completo</p>
            <p className='details-text'>{user.name}</p>

            <p className='details-title'>CPF</p>
            <p className='details-text'>{Conversor.converterCpf(user.cpf.toString())}</p>

            <p className='details-title'>Telefone</p>
            <p className='details-text'>{Conversor.converterFormatoTelefone(user.cellNumber.toString())}</p>

            <p className='details-title'>Email</p>
            <p className='details-text'>{user.email}</p>

            <p className='details-title'>Data de Criação</p>
            <p className='details-text'>{Conversor.converterData(user.created)}</p>
          </div>
        </div>

        {user.store ? (
          <div className="container-two">
            <div className="order-follow-up-container delivery-man-container">
              <h4>Dados da Loja</h4>
              <p>Nome da Loja</p>
              <p className='details-text'>{user.store.companyName}</p>

              <p className='details-title'>CNPJ</p>
              <p className='details-text'>{Conversor.converterCNPJ(user.store.cnpj)}</p>

              <p className='details-title'>Telefone</p>
              <p className='details-text'>{Conversor.converterFormatoTelefone(user.store.cellNumber.toString())}</p>

              <p className='details-title'>CEP</p>
              <p className='details-text'>{Conversor.converterCEP(user.store.address.cep.toString())}</p>

              <p className='details-title'>Endereço</p>
              <p className='details-text'>
                {
                  storeAddress.street + ', ' + storeAddressNumber + storeAddressComplement +
                  storeAddress.neighborhood + ', ' + storeAddress.city + '/' + storeAddress.state
                }
              </p>
            </div>
          </div>
        ) : (
          <div className="container-two">
            <div className="order-follow-up-container delivery-man-container">
              <h4>Dados do Veículo</h4>
              <p>Modelo</p>
              <p className='details-text'>{user.vehicle.model}</p>

              <p className='details-title'>Placa</p>
              <p className='details-text'>{user.vehicle.vehiclePlate}</p>

              <p className='details-title'>Tipo</p>
              <p className='details-text'>{user.vehicle.type === "motorcycle" ? "Motocicleta" : "Carro"}</p>

              <p className='details-title'>Cor</p>
              <p className='details-text'>{user.vehicle.color}</p>
            </div>
          </div>
        )}

      </div>
      <div className='modal-actions-container' style={{ justifyContent: 'center', display: 'flex', paddingTop: 50 }}>
        <button className="btn btn-info" style={{ backgroundColor: "#218838", width: 150, marginRight: 30 }} onClick={() => aproveUser(user._id, user.email)}>
          <FontAwesomeIcon icon={faCheck} title='Aprovar Usuário' style={{ marginRight: 10, height: 15 }} />
          Aprovar
        </button>
        <button className="btn btn-danger cancel-order-btn" onClick={() => disaproveUser(user._id, user.email)} style={{ width: 150 }}>
          <FontAwesomeIcon icon={faTimes} color='white' title='Reprovar Usuário' style={{ marginRight: 10, height: 15 }} />
          Reprovar
        </button>
      </div>

    </div>
  );
};

const UsersList = ({ users, nextPage, lastPage,
  aproveUser, disaproveUser, title }) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const openModal = (user) => {
    setSelectedUser(user);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setModalVisible(false);
  };

  console.log(users);

  return (
    <div className="table-orders-container">
      <h1 className="page-title" style={{ marginBottom: 30 }}>{title}</h1>
      <table>
        <thead>
          <tr>
            <th className='th-delivery-code'>Data de Cadastro</th>
            <th>Tipo</th>
            <th>Lojista</th>
            <th>CPF</th>
            <th>Loja/Veículo</th>
            <th>CPNJ/Placa</th>
            <th style={{ width: "10%", textAlign: 'center' }}>Visualizar</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            if (user.store) {
              return (
                <tr key={user._id}>
                  <td className='td-delivery-code'>{Conversor.converterData(user.created)}</td>
                  <td>Lojista</td>
                  <td>{user.name}</td>
                  <td>{Conversor.converterCpf(user.cpf.toString())}</td>
                  <td>{user.store.companyName}</td>
                  <td>{Conversor.converterCNPJ(user.store.cnpj)}</td>
                  <td className='td-actions-buttons' style={{ textAlign: 'center' }}>
                    <button className="btn btn-info open-modal-btn" onClick={() => openModal(user)}><FontAwesomeIcon icon={faSearch} title='Detalhes do Pedido' /></button>
                  </td>
                </tr>
              );
            } else {
              return (
                <tr key={user._id}>
                  <td className='td-delivery-code'>{Conversor.converterData(user.created)}</td>
                  <td>Entregador</td>
                  <td>{user.name}</td>
                  <td>{Conversor.converterCpf(user.cpf.toString())}</td>
                  <td>{user.vehicle.model}</td>
                  <td>{user.vehicle.vehiclePlate}</td>
                  <td className='td-actions-buttons' style={{ textAlign: 'center' }}>
                    <button className="btn btn-info open-modal-btn" onClick={() => openModal(user)}><FontAwesomeIcon icon={faSearch} title='Detalhes do Pedido' /></button>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
      <div className="pagination-buttons">
        <button className="btn page-btn" onClick={lastPage}><FontAwesomeIcon icon={faChevronLeft} style={{ height: 12, marginBottom: 2 }} /></button>
        <button className="btn page-btn" onClick={nextPage}><FontAwesomeIcon icon={faChevronRight} style={{ height: 12, marginBottom: 2 }} /></button>
      </div>
      {modalVisible && (
        <div className="modal-overlay">
          <UserDetailsModal user={selectedUser} closeModal={closeModal} modalVisible={modalVisible} aproveUser={aproveUser} disaproveUser={disaproveUser} />
        </div>
      )}
    </div>
  );
};

export default UsersList;