import motumHomeMap from "../../../../assets/img/motum-home-map.png";

export default function HomePage(props){

    return (
        <div className="home-page-container">
            <div className="left-container">
                <h1>De Onde Você Estiver.</h1>
                <h1>Para Qualquer Lugar de Sergipe.</h1>
                <p>Mais rápido e mais acessível!</p>
                <button type="button" onClick={() => props.setRedirect(true)}>Enviar Agora</button>
            </div>
            <div className="right-container">
                <img src={motumHomeMap} alt="Mapa Motum" />
            </div>
        </div>
    );
}