import { useEffect, useMemo, useState } from "react";
import Select from 'react-select';
import AdminService from "../../../services/AdminService";
import Conversor from "../../../helper/Conversor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faClock, faClose, faPlus, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import './style.scss';
import Swal from "sweetalert2";
import Inputmask from 'inputmask';

export default function ManageWallet(props) {
    const [loading, setLoading] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const adminService = useMemo(() => new AdminService(), []);

    async function loadWallets() {
        if (loading) {
            return;
        }
        setLoading(true);
        props.setSpinner(true);
        setTimeout(async () => {
            const response = await adminService.getWallets();
            setWallets(response);
            props.setSpinner(false)
        }, 2000);
    }

    useEffect(() => {
        loadWallets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    function handleChangeUser(userOption) {
        props.setSpinner(true);
        setSelectedWallet(wallets[wallets.findIndex(wallet => wallet._id === userOption.value)]);
        setTimeout(() => {
            props.setSpinner(false);
        }, 2000)
    }

    const usersOptions = wallets.map(({ _id, name }) => ({ value: _id, label: name }));

    const transactionMap = {
        "CREDIT": "CRÉDITO",
        "DEBIT": "DÉBITO",
        "PENDING": "PENDENTE",
        "APROVED": "APROVADO",
        "CANCELLED": "CANCELADO"
    }

    const transactionIconMap = {
        "APROVED": <FontAwesomeIcon icon={faCheckSquare} color="#168821ff" style={{ marginRight: 10 }} />,
        "PENDING": <FontAwesomeIcon icon={faClock} color="#ffcd07ff" style={{ marginRight: 10 }} />,
        "CANCELLED": <FontAwesomeIcon icon={faWindowClose} color="#e52207ff" style={{ marginRight: 10 }} />,
    };

    const handleRemoveBalance = async () => {
        const { value: saldo } = await Swal.fire({
            title: 'Remover Saldo',
            html: `<input id="saldo-input" type="text" class="swal2-input" placeholder="R$ 0,00" />`,
            showCancelButton: true,
            confirmButtonText: 'Remover',
            confirmButtonColor: '#e52207ff',
            cancelButtonText: 'Cancelar',
            didOpen: () => {
                // Aplica a máscara ao campo de entrada usando Inputmask
                const input = document.getElementById('saldo-input');
                Inputmask({
                    alias: 'numeric',
                    groupSeparator: '.',
                    radixPoint: ',',
                    prefix: 'R$ ',
                    autoGroup: true,
                    digits: 2,
                    digitsOptional: false,
                    placeholder: '0',
                    clearMaskOnLostFocus: false
                }).mask(input);
            },
            preConfirm: () => {
                // Retorna o valor do input sem a máscara
                const input = document.getElementById('saldo-input').value;
                const numericValue = parseFloat(input.replace('R$ ', '').replace(/\./g, '').replace(',', '.'));

                if (isNaN(numericValue) || numericValue <= 0) {
                    Swal.showValidationMessage('Por favor, insira um valor válido!');
                    return null;
                }

                return numericValue;
            }
        });

        if (saldo) {
            try {
                props.setSpinner(true);
                await adminService.removeBalance(selectedWallet.wallet._id, saldo);
                props.setSpinner(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Saldo removido!',
                    text: `Você removeu R$${saldo.toFixed(2).replace('.', ',')}`,
                    showConfirmButton: false,
                    timer: 2000
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro!',
                    text: 'Tente novamente',
                    showConfirmButton: false,
                    timer: 2000
                });
            }
            
        }
    }

    const handleAddBalance = async () => {
        const { value: saldo } = await Swal.fire({
            title: 'Adicionar Saldo',
            html: `<input id="saldo-input" type="text" class="swal2-input" placeholder="R$ 0,00" />`,
            showCancelButton: true,
            confirmButtonText: 'Adicionar',
            confirmButtonColor: '#168821ff',
            cancelButtonText: 'Cancelar',
            didOpen: () => {
                // Aplica a máscara ao campo de entrada usando Inputmask
                const input = document.getElementById('saldo-input');
                Inputmask({
                    alias: 'numeric',
                    groupSeparator: '.',
                    radixPoint: ',',
                    prefix: 'R$ ',
                    autoGroup: true,
                    digits: 2,
                    digitsOptional: false,
                    placeholder: '0',
                    clearMaskOnLostFocus: false
                }).mask(input);
            },
            preConfirm: () => {
                // Retorna o valor do input sem a máscara
                const input = document.getElementById('saldo-input').value;
                const numericValue = parseFloat(input.replace('R$ ', '').replace(/\./g, '').replace(',', '.'));

                if (isNaN(numericValue) || numericValue <= 0) {
                    Swal.showValidationMessage('Por favor, insira um valor válido!');
                    return null;
                }

                return numericValue;
            }
        });

        if (saldo) {
            try {
                props.setSpinner(true);
                await adminService.addBalance(selectedWallet.wallet._id, saldo);
                props.setSpinner(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Saldo adicionado!',
                    text: `Você adicionou R$${saldo.toFixed(2).replace('.', ',')}`,
                    showConfirmButton: false,
                    timer: 2000
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro!',
                    text: 'Tente novamente',
                    showConfirmButton: false,
                    timer: 2000
                });
            }
            
        }
    }

    return (
        <div className="content-container manage-wallet-container">
            <div className="title">Gerenciar carteiras</div>
            <div className="select-users-container">
                <Select
                    options={usersOptions}
                    onChange={handleChangeUser}
                    placeholder="Selecione um usuário"
                    className="select-user"
                />
            </div>
            {selectedWallet ? (
                <div className="selected-wallet-container">
                    <div className="wallet-details-container">
                        <div className="wallet-title">Detalhes da Carteira</div>
                        <div className="wallet-user-name">
                            <div className="user-title">Usuário</div>
                            <div className="user-content">{selectedWallet ? selectedWallet.name : "***********"}</div>
                        </div>
                        <div className="wallet-balance">
                            <div className="amount-title">Saldo Disponível</div>
                            <div className="amount-value">{selectedWallet ? Conversor.converterStringReal(selectedWallet.wallet.amount.value) : "***********"}</div>
                        </div>
                        <div className="wallet-actions">
                            <button type="button" className="add-balance" onClick={handleAddBalance}>Adicionar Saldo <FontAwesomeIcon icon={faPlus} /></button>
                            <button type="button" className="remove-balance" onClick={handleRemoveBalance}>Remover Saldo <FontAwesomeIcon icon={faClose} /></button>
                        </div>
                    </div>
                    <div className="wallet-list-transactions">
                        <table>
                            <thead>
                                <tr>
                                    <th className="transaction-date">Data da transação</th>
                                    <th className="transaction-type">Tipo</th>
                                    <th className="transaction-value">Valor</th>
                                    <th className="transaction-description">Descrição</th>
                                    <th className="transaction-status">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedWallet && selectedWallet.wallet.transactions
                                    .sort((a, b) => new Date(b.movementAt) - new Date(a.movementAt))
                                    .map((transaction, index) => (
                                        <tr key={index}>
                                            <td className="transaction-date">{Conversor.converterData(transaction.movementAt)}</td>
                                            <td className={`transaction-type${transaction.type === "CREDIT" ? "-credit" : "-debit"}`}>{transactionMap[transaction.type]}</td>
                                            <td className={`transaction-value${transaction.type === "CREDIT" ? "-credit" : "-debit"}`}>{transaction.type === "CREDIT" ? "+ " : "- "}{Conversor.converterStringReal(transaction.amount.value)}</td>
                                            <td className="transaction-description">{transaction.description ? transaction.description : "Não informado"}</td>
                                            <td className="transaction-status">{transactionIconMap[transaction.status]}{transactionMap[transaction.status]}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (null)}
        </div>
    )
}