import { useEffect, useMemo, useState } from "react";
import Select from 'react-select';
import AdminService from "../../../services/AdminService";
import InputMask from 'react-input-mask';
import './style.scss';
import Swal from "sweetalert2";

export default function ManageUsers(props) {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({
        name: '',
        email: '',
        cpf: '',
        cellNumber: '',
        address: {
            cep: '',
            city: '',
            street: '',
            address_number: '',
            neighborhood: '',
            complement: ''
        }
    });

    const adminService = useMemo(() => new AdminService(), []);

    async function loadUsers() {
        if (loading) {
            return;
        }
        setLoading(true);
        props.setSpinner(true);
        setTimeout(async () => {
            const response = await adminService.getUsers();
            setUsers(response);
            props.setSpinner(false)
        }, 2000);
    }

    useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name.includes('address.')) {
            const addressField = name.split('.')[1];
            setUser(prevUser => ({
                ...prevUser,
                address: {
                    ...prevUser.address,
                    [addressField]: value
                }
            }));
        } else {
            setUser(prevUser => ({
                ...prevUser,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            props.setSpinner(true);
            const response = await adminService.updateUser(user._id, user);
            props.setSpinner(false);
            setLoading(false);
            console.log(response);
            Swal.fire({
                icon: 'success',
                title: 'Dados atualizado com sucesso!',
                showConfirmButton: false,
                timer: 2000
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro',
                showConfirmButton: false,
                timer: 2000
            });
        }
    };

    function handleChangeUser(userOption) {
        props.setSpinner(true);
        setUser(users[users.findIndex(user => user._id === userOption.value)]);
        setTimeout(() => {
            props.setSpinner(false);
        }, 2000)
    }

    const usersOptions = users.map(({ _id, name }) => ({ value: _id, label: name }));

    return (
        <div className="content-container manage-users-container">
            <div className="title">Alterar Cadastro</div>
            <div className="sub-title">Selecione um usuário</div>
            <div className="select-user-container">
                <Select
                    options={usersOptions}
                    onChange={handleChangeUser}
                    placeholder="Selecione um usuário"
                    className="select-user"
                />
            </div>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label>Nome:</label>
                    <input
                        type="text"
                        name="name"
                        value={user.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>CPF:</label>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu CPF'
                        type="text"
                        name="cpf"
                        mask="999.999.999-99" // Máscara para CPF
                        value={user.cpf}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Telefone:</label>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu telefone'
                        type="text"
                        name="cellNumber"
                        mask="(99) 99999-9999" // Máscara para Telefone
                        value={user.cellNumber}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>CEP:</label>
                    <InputMask
                        className="form-control"
                        placeholder='Digite seu CEP'
                        type="text"
                        name="address.cep"
                        mask="99999-999" // Máscara para CEP
                        value={user.address.cep}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Cidade:</label>
                    <input
                        type="text"
                        name="address.city"
                        value={user.address.city}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Rua:</label>
                    <input
                        type="text"
                        name="address.street"
                        value={user.address.street}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Número:</label>
                    <input
                        type="number"
                        name="address.address_number"
                        value={user.address.address_number}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Bairro:</label>
                    <input
                        type="text"
                        name="address.neighborhood"
                        value={user.address.neighborhood}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-control">
                    <label>Complemento:</label>
                    <input
                        type="text"
                        name="address.complement"
                        value={user.address.complement}
                        onChange={handleChange}
                    />
                </div>

                {user._id ? (
                    <div className="form-control">
                        <button type="submit">Salvar Alterações</button>
                    </div>
                ) : (null)}
            </form>
        </div>
    );
}