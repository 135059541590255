import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";

export default function Reports(props) {
    return (
        <div className="content-container reports container">
            <div className="in-progress-message">
                <FontAwesomeIcon icon={faCode} /> 
                Estamos desenvolvendo essa página. Fique ligado nas próximas atualizações!
            </div>
        </div>
    );
}